<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-4 column">
              <div class="heading-profile">
                <h3>Service Details</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right"></div>
                  </div>
                  <div class="form-section">
                    <div class="form-detail pt-3">
                      <b-row>
                        <b-col md="4">
                          <b-input v-model="detail.name" readonly></b-input>
                        </b-col>
                        <b-col md="4">
                          <b-input v-model="detail.email" readonly></b-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="4">
                          <b-input class="mt-4" v-model="detail.mobile" readonly></b-input>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="seeking-section pt-4">
                      <div class="font-14 title-section pb-2 font-weight-bold">
                        Seeking for:
                      </div>
                      <div class="font-14 title-section pb-2">
                        {{ detail.seekingFor }}
                      </div>
                    </div>
                    <div class="ocassion-section pt-3">
                      <div class="font-14 ocassion-title pb-2 font-weight-bold">
                        Occasion:
                      </div>
                      <div class="font-14 ocassion-title pb-2 ">
                        {{ detail.occasion }}
                      </div>
                    </div>

                    <div class="preferred-hotel-section pt-3">
                      <b-row>
                        <b-col md="4">
                          <b-form-group>
                            <label class="font-weight-bold">Preferred Hotel/Resort</label>
                            <div>{{ detail.preferredHotel }}</div>
                            <!-- <b-input readonly v-model="detail.PreferredHotel"> </b-input> -->
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="font-14 function-date pt-3">
                      <div class="pb-2 font-weight-bold">
                        Function Date:
                      </div>
                      <div>
                        <b-row>
                          <b-col md="4">
                            <div>
                              <b-input v-model="detail.fromDate" readonly />
                            </div>
                          </b-col>

                          <b-col md="4">
                            <div>
                              <b-input v-model="detail.fromDate" readonly />
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div class="night-section pt-3">
                      <div class="font-14 pb-2 font-weight-bold">
                        No. of Nights:
                      </div>
                      <div>
                        <b-row>
                          <b-col md="4">
                            <div>
                              <b-input v-model="detail.noOfNights" readonly></b-input>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div class="remark-section pt-3">
                      <div class="font-14 pb-2 font-weight-bold">
                        Remarks:
                      </div>
                      <div v-if="detail.remark">
                        <b-row>
                          <b-col md="8">
                            <b-input v-model="detail.remark" readonly></b-input>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div>
                      <b-form-group>
                        <label class="font-18 pt-3 font-weight-bold">Status</label>
                        <b-row>
                          <b-col md="4">
                            <b-form-select
                              v-model="detail.status"
                              :options="options"
                            ></b-form-select>
                          </b-col>
                        </b-row>
                        <label class="font-18 pt-3 font-weight-bold">Person Assign Details</label>
                        <b-row>
                          <b-col md="4">
                            <b-input type="text" v-model="detail.personAssignDetails"></b-input>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </div>
                    <div class="btn-section text-center">
                      <b-button
                        id="slideNext"
                        border-0
                        class="continue-btn shadow-none mr-2"
                        variant="success"
                        @click="saveData()"
                        >Save</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import headerCustom from "@/components/Header.vue";
import apiservice from "@/service/apiservice";
import moment from "moment";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      detail: {
        status: "",
        personAssignDetails: ""
      },
      id: "",
      options: [
        {
          text: "Active",
          value: "active"
        },
        {
          text: "Close",
          value: "close"
        }
      ]
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.viewDetail();
  },
  methods: {
    viewDetail() {
      apiservice.getOneHireExpertNDeals(this.id, data => {
        if (data.code === 200) {
          this.detail = data.data;
          this.detail.fromDate = moment(this.detail.fromDate).format("MMMM DD YYYY");
          this.detail.toDate = moment(this.detail.toDate).format("MMMM DD YYYY");
          this.detail.preferredHotel = this.detail.preferredHotel.map(x => x.name).join(", ");
        } else {
        }
      });
    },
    saveData() {
      var obj = {
        status: this.detail.status,
        personAssignDetails: this.detail.personAssignDetails
      };
      apiservice.updateHireExpertNDeals(obj, this.id, result => {
        if (result.status === 200) {
          this.$toaster.success("Data Saved");
        }
      });
    }
  }
};
</script>

<style></style>
